:root {
    --attachVar: fixed;
}

html {
    height:100%;
}

body {
    background: url(background.jpg);
    background-size: cover;
    background-attachment: var(--attachVar);
    min-height: 100%;
    min-width: 100vw;
    position: relative;
    display: inline-block;
    align-items: center;
}

@media screen and (hover: none) {
    body{
        background: url(background.jpg);
        background-size: cover;
        background-attachment: fixed;
        min-height: 100%;
        min-width: 100vw;
        position: relative;
        display: inline-block;
        align-items: center;
    }
}

.formtext {
    margin-left: '10px';
    margin-top: '20px';
    margin-bottom: '0';
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

.container {
    background-color: rgb(255, 255, 255, 0.9);
    border-radius: 12px;
    padding-bottom: 40px;
    padding-top: 5px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-width: min-content;
    display: inline-block;
    text-align: left;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

.container > * {
    margin: 20px;
    text-align: left;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

h2 {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 40px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

h1, h3, h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

input {
    margin: 10px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

button {
    margin-right: 5px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

.footer {
    position: absolute;
    box-sizing: border-box;
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    bottom: 0px;
    z-index: 100;
    background: rgb(223, 255, 230, 0.95);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    left: 0px;
    right: 0px;
    width: 100%;
    height: auto;
    min-height: min-content;
    border-radius: 3px;
    box-shadow: 0 -0.5px 2px 1px grey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.navbar-text {
    padding-top: 15px;
    padding-left: 10px;
    vertical-align: bottom;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
}

.footer-ghost {
    height: 120px; 
}

.nav-colour {
    background-color: rgb(112, 182, 130, 0.95);
    border-radius: 3px;
    box-shadow: 0 -0.5px 2px 1px grey;
}

.link {
    color: white;
    font-family: Arial, Helvetica, sans-serif;    
    font-style: normal;
    text-shadow: 2px 2px 2px rgba(100, 150, 150, 1);
}

.description {
    background-color: rgb(112, 182, 130, 0.8);
    color: white;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 2px 2px 2px rgba(100, 150, 150, 1);
    border-radius: 12px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    min-width: min-content;
    text-align: center;
}

.instruct-img {
    font-style: italic;
    border-radius: 12px;
    min-width: min-content;
}

.logged-user {
    color: white;
    text-decoration: underline;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
}

.navbar-nav {
    margin: auto;
    padding-top: 0;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
}

.full {
    padding: 0;
    margin: 0;
    border-bottom: 0;
    bottom: 0;
    min-height: 100%;
}

.page {
    padding: 0;
    margin: 0;
    border-bottom: 0;
    bottom: 0;
    min-height: 100%;
    min-width: min-content;
    align-content: center;
    align-self: center;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
}

.nav-link {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 2px;
    height: 62px;
}

.nav-text {
    margin-top: 0;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
}

.hidden-nav {
    height: 7vh;
    min-height: 7vh;
    max-height: 7vh
}

.nav-bar {
    max-height: 10vh;
    min-height: 10vh;
}

#samples {
    background: #f5f5f5;
}

#testView {
    background: #f5f5f5;
}

.game-margin {
    margin: 10px 0 10px 0;
}

.small-margin-float-right {
    margin: 2px;
    float: right;
}

.modal-image {
    position: fixed;
    padding: 0 !important;
    margin: 0;
    border: 0;
    top: 10px;
}

.modal-image-preview {
    max-width: 5em;
}

.modal-image-large {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    top: 0;
    height: auto;
    display: block;
}

.modal-image-container {
    align-items: center;
    padding: 0;
    max-width: max-content;
    height: auto;
    display: "block";
}

.small-margin {
    margin: 2px;
}

.required-field::after {
    content: " *";
    color: red;
  }

